import React, { useState } from "react";
import styled from "styled-components";
import {
  innerWidth,
  mainWhite,
  mainBlack,
  mainPurple,
  screen,
  siteWidth,
  transHover,
} from "../variables";
import { Link } from "gatsby";
import Logo from "../../images/svg/clearr-brand.svg";
import { AnimatePresence, motion } from "framer-motion";
import { IoIosArrowDown } from "react-icons/io";
import OverlayMenu from "./overlay-menu";
import OverlayBg from "./overlay-bg";
import { FiMenu, FiX } from "react-icons/fi";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.133,
    },
  },
};

const item = {
  hidden: { opacity: 0, x: -15 },
  show: { opacity: 1, x: 0 },
};

const Wrapper = styled.nav`
  max-width: ${siteWidth};
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  width: 100%;
  background: ${mainWhite};
  box-shadow: 0 0 7px 0 rgba(66, 61, 61, 0.1);
  z-index: 20;

  .inner-wrapper {
    max-width: ${innerWidth};
    margin: 0 auto;
    padding: 0 25px;
    position: relative;
    height: 70px;
    @media ${screen.xsmall} {
      padding: 0 40px;
      height: 100px;
    }
    @media ${screen.medium} {
      padding: 0;
    }
  }

  .logo {
    position: absolute;
    display: block;
    left: 25px;
    top: 50%;
    transform: translate(0, -50%);
    @media ${screen.xsmall} {
      left: 40px;
    }
    @media ${screen.medium} {
      left: 0;
    }

    svg {
      width: 120px;
      @media ${screen.xsmall} {
        width: 196px;
      }
      @media ${screen.small} {
        width: 170px;
      }
      @media ${screen.medium} {
        width: 215px;
      }
    }
  }

  .icon-menu {
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translate(0, -50%);
    font-size: 2rem;
    @media ${screen.xsmall} {
      right: 40px;
      font-size: 2.4rem;
    }
    @media ${screen.small} {
      display: none;
    }
  }

  .nav-list {
    display: none;
    @media ${screen.small} {
      display: flex;
      position: absolute;
      right: 25px;
      top: 50%;
      transform: translate(0, -50%);
    }
    @media ${screen.medium} {
      right: 0;
    }

    &__each {
      margin: 0 0 0 3.6vw;
      @media ${screen.medium} {
        margin: 0 0 0 60px;
      }

      a {
        font-size: 1.12rem;
        font-weight: 500;
        color: ${mainBlack};
        @media ${screen.xsmall} {
          font-size: 1rem;
        }
        @media ${screen.medium} {
          font-size: 1.12rem;
        }

        @media ${screen.withCursor} {
          &:hover {
            color: ${mainPurple};
          }
        }
      }

      .active {
        color: ${mainPurple};
      }

      &--dropdown {
        position: relative;

        .icon-arrow {
          position: absolute;
          top: 4px;
          right: -21px;

          svg {
            transition: ${transHover};
          }

          &--is-open {
            svg {
              transform: rotate(-180deg) translateY(1px);
            }
          }
        }

        .sublink-list {
          background: rgba(255, 255, 255, 0.9);
          position: absolute;
          top: 58px;
          left: -29px;
          padding: 22px 30px;
          border-radius: 0 0 15px 15px;
          @media ${screen.medium} {
            top: 60px;
          }

          &__each {
            display: block;
            white-space: pre;
            margin: 0 0 20px 0;

            &:last-child {
              margin: 0;
            }

            a {
            }
          }
        }
      }
    }
  }
`;

const Navbar = () => {
  const [isIndustriesDropdown, setIsIndustriesDropdown] = useState(false);
  const [isOverlayMenu, setIsOverlayMenu] = useState(false);

  const toggleIndustriesDropdown = (e) => {
    e.preventDefault();

    setIsIndustriesDropdown(!isIndustriesDropdown);
  };

  const toggleOverlayMenu = () => {
    setIsOverlayMenu(!isOverlayMenu);
  };

  const closeAll = () => {
    if (isIndustriesDropdown) setIsIndustriesDropdown(false);
    if (isOverlayMenu) setIsOverlayMenu(false);
  };

  return (
    <>
      <Wrapper>
        <div className="inner-wrapper">
          <Link className="logo" to="/">
            <Logo />
          </Link>

          <div className="icon-menu" onClick={toggleOverlayMenu} role="button">
            {isOverlayMenu ? <FiX /> : <FiMenu />}
          </div>

          <ul className="nav-list">
            <li className="nav-list__each">
              <Link activeClassName="active" to="/services/">
                Services
              </Link>
            </li>
            <li className="nav-list__each">
              <Link activeClassName="active" to="/about/">
                About Us
              </Link>
            </li>
            <li className="nav-list__each nav-list__each--dropdown">
              <Link
                activeClassName="active"
                to="/industry/"
                onClick={toggleIndustriesDropdown}
              >
                Industries
                <span
                  className={
                    isIndustriesDropdown
                      ? "icon-arrow icon-arrow--is-open"
                      : "icon-arrow"
                  }
                >
                  <IoIosArrowDown />
                </span>
              </Link>

              <AnimatePresence>
                {isIndustriesDropdown && (
                  <motion.div
                    variants={container}
                    initial="hidden"
                    animate={isIndustriesDropdown ? "show" : "hidden"}
                    exit={{ opacity: 0 }}
                    className="sublink-list"
                  >
                    <motion.p
                      variants={item}
                      onClick={closeAll}
                      className="sublink-list__each"
                    >
                      <Link to="/industry/">All</Link>
                    </motion.p>
                    <motion.p
                      variants={item}
                      onClick={closeAll}
                      className="sublink-list__each"
                    >
                      <AnchorLink to="/industry/#financial-services">
                        Financial Services
                      </AnchorLink>
                    </motion.p>
                    <motion.p
                      variants={item}
                      onClick={closeAll}
                      className="sublink-list__each"
                    >
                      <AnchorLink to="/industry/#government">
                        Government
                      </AnchorLink>
                    </motion.p>
                    <motion.p
                      variants={item}
                      onClick={closeAll}
                      className="sublink-list__each"
                    >
                      <AnchorLink to="/industry/#healthcare">
                        HealthCare
                      </AnchorLink>
                    </motion.p>
                    <motion.p
                      variants={item}
                      onClick={closeAll}
                      className="sublink-list__each"
                    >
                      <AnchorLink to="/industry/#not-for-profit">
                        Not for Profit
                      </AnchorLink>
                    </motion.p>
                    <motion.p
                      variants={item}
                      onClick={closeAll}
                      className="sublink-list__each"
                    >
                      <AnchorLink to="/industry/#telecommunications-and-logistics">
                        Telecommunications & Logistics
                      </AnchorLink>
                    </motion.p>
                    <motion.p
                      variants={item}
                      onClick={closeAll}
                      className="sublink-list__each"
                    >
                      <AnchorLink to="/industry/#higher-education">
                        Higher Education
                      </AnchorLink>
                    </motion.p>
                  </motion.div>
                )}
              </AnimatePresence>
            </li>
            <li className="nav-list__each">
              <Link activeClassName="active" to="/tableau/">
                ClearR + Tableau
              </Link>
            </li>
            <li className="nav-list__each">
              <Link activeClassName="active" to="/salesforce/">
                ClearR + Salesforce
              </Link>
            </li>
            <li className="nav-list__each">
              <Link activeClassName="active" to="/quickstart/">
                Quickstart
              </Link>
            </li>
          </ul>
        </div>
      </Wrapper>
      <OverlayMenu
        isOverlayMenu={isOverlayMenu}
        setIsOverlayMenu={setIsOverlayMenu}
      />
      <OverlayBg
        isIndustriesDropdown={isIndustriesDropdown}
        setIsIndustriesDropdown={setIsIndustriesDropdown}
      />
    </>
  );
};

export default Navbar;
