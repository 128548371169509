import React from "react";
import "../css/global.css";
import styled from "styled-components";
import { mainFont, darkPurple, siteWidth, mainWhite } from "./variables";
import Nav from "../components/nav/navbar";
import Footer from "../components/footer";
import LightboxRebrand from "../components/lightbox-rebrand";

const Wrapper = styled.div`
  background: ${mainWhite};
  color: ${darkPurple};
  font-family: ${mainFont};
  max-width: ${siteWidth};
  margin: 0 auto;
`;

const Layout = ({ children }) => {
  return (
    <Wrapper>
      <Nav />
      <main>{children}</main>
      <LightboxRebrand />
      <Footer />
    </Wrapper>
  );
};

export default Layout;
