import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  background: rgba(46, 41, 78, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 18;

  &::after {
  }
`;

const OverlayBg = ({ isIndustriesDropdown, setIsIndustriesDropdown }) => {
  const toggleIndustriesDropdown = (e) => {
    e.preventDefault();
    setIsIndustriesDropdown(!isIndustriesDropdown);
  };

  return (
    isIndustriesDropdown && (
      <Wrapper onClick={toggleIndustriesDropdown}></Wrapper>
    )
  );
};

export default OverlayBg;
