export const organization = {
  "@context": "https://schema.org",
  "@type": "Organization",
  name: "CLEARR",
  legalName: "CLEARR PTY LTD",
  url: "https://www.clearr.com.au",
  logo: "https://www.clearr.com.au/clearr-logo.jpg",
  foundingDate: "2018",
  founders: [
    {
      "@type": "Person",
      name: "Niranjan Sundararaj",
    },
  ],
  address: {
    "@type": "PostalAddress",
    streetAddress: "evel 20, Tower 2/201 Sussex St,",
    addressLocality: "evel 20, Tower 2/201 Sussex St, Sydney",
    addressRegion: "Australia",
    postalCode: "2000",
    addressCountry: "AU",
  },
  contactPoint: {
    "@type": "ContactPoint",
    contactType: "customer support",
    telephone: "[1300 341 391]",
    email: "contactus@clearr.com.au",
  },
  sameAs: [
    "https://www.linkedin.com/company/visioneer360",
    "https://www.facebook.com/visioneer360",
  ],
};

export const getArticleSchema = (
  headline,
  alternativeHeadline,
  image,
  wordcount,
  url,
  datePublished,
  description,
  articleBody
) => {
  return {
    "@context": "https://schema.org",
    "@type": "Article",
    headline: headline,
    alternativeHeadline: alternativeHeadline,
    image: image ? image : "https://www.clearr.com.au/clearr-logo.jpg",
    author: "Marketing Manager​",
    genre: "Technology",
    keywords: "clearr",
    wordcount: wordcount,
    publisher: {
      "@type": "Organization",
      name: "ClearR",
      logo: {
        "@type": "ImageObject",
        url: "https://www.clearr.com.au/clearr-logo.jpg",
      },
    },
    url,
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": url,
    },
    datePublished: datePublished,
    dateCreated: datePublished,
    description,
    articleBody,
  };
};
