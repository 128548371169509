import React from "react";
import styled from "styled-components";
import {
  innerWidth,
  mainWhite,
  mainBlack,
  mainPurple,
  mainBlue,
  screen,
  transHover,
} from "../variables";
import { Link, graphql, useStaticQuery } from "gatsby";
import Logo from "../../images/svg/clearr-brand.svg";
import SalesforceBrand1 from "../../images/svg/SaleforcePartner-Logo_SalesforcePartner-Logo-RGB.svg";
import SalesforceBrand2 from "../../images/svg/SaleforcePartner-Logo_SalesforceConsultingPartner-Logo-RGB-01.svg";
import TableauBrand from "../../images/svg/tableaupartnerlogo.svg";
import Obfuscate from "react-obfuscate";
import { FaTelegramPlane } from "react-icons/fa";

const Wrapper = styled.footer`
  .inner-wrapper {
    max-width: ${innerWidth};
    margin: 0 auto;
    padding: 0 25px;
    @media ${screen.xsmall} {
      padding: 0 40px;
    }
    @media ${screen.medium} {
      padding: 0;
    }
  }

  .top {
    background: ${mainBlack};
    color: ${mainWhite};
    padding: 55px 0;
    @media ${screen.xsmall} {
      padding: 70px 0;
    }

    .flex {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .heading {
        font-size: 1.15rem;
        font-weight: 700;
        margin: 0 0 20px 0;
        @media ${screen.xsmall} {
          font-size: 1.25rem;
        }
        @media ${screen.small} {
          font-size: 1.3rem;
        }
        @media ${screen.medium} {
          font-size: 1.35rem;
        }
      }
      .col {
        &--one {
          width: 100%;
          @media ${screen.xsmall} {
            width: 50%;
          }
          @media ${screen.small} {
            width: auto;
          }
          .logo {
            svg {
              width: 110px;
              margin: 0 0 40px 0;
              @media ${screen.xsmall} {
                width: 170px;
              }
              .be77a91c-ce6f-4ce0-bb3a-2a072d348bd9 {
                fill: ${mainWhite};
              }

              .b32bb9a1-6c9a-4162-8145-0829ce0bbd4d {
                fill: ${mainWhite};
              }
            }
          }

          .form {
            p {
              display: flex;
              input {
                width: 200px;
                font-size: 16px;
                padding: 6px 10px;
                outline: none;
                border: 0;
                border-radius: 10px 0 0 10px;
                @media ${screen.xsmall} {
                  width: 215px;
                  padding: 12px 13px;
                }
              }

              button {
                all: unset;
                display: block;
                background: ${mainPurple};
                padding: 11px 14px 8px 11px;
                border-radius: 0 10px 10px 0;
                cursor: pointer;
                transition: ${transHover};
                overflow: hidden;
                position: relative;
                @media ${screen.withCursor} {
                  &:hover {
                    background: ${mainBlue};
                  }
                }
                svg {
                  font-size: 1.65rem;
                }

                span {
                  clip: rect(1px, 1px, 1px, 1px);
                  height: 1px;
                  position: absolute;
                  width: 1px;
                }
              }
            }
          }

          .social {
            margin: 50px 0 0 0;

            .social-list {
              display: flex;
              align-items: center;

              a {
                color: ${mainWhite};
                display: inline-block;
                font-size: 0.95rem;
                font-weight: 300;
                @media ${screen.xsmall} {
                  font-size: 1.1rem;
                }

                @media ${screen.withCursor} {
                  &:hover {
                    color: ${mainBlue};
                  }
                }
              }

              span {
                font-size: 1rem;
                margin: 0 8px;
                font-weight: 300;
              }
            }
          }
        }

        &--two,
        &--three {
          p {
            font-size: 0.95rem;
            margin: 0 0 9px 0;
            @media ${screen.xsmall} {
              font-size: 1.1rem;
              margin: 0 0 10px 0;
            }

            a {
              color: ${mainWhite};
              font-weight: 300;
              @media ${screen.withCursor} {
                &:hover {
                  color: ${mainBlue};
                }
              }
            }

            .address {
              display: block;
              max-width: 200px;
              @media ${screen.xsmall} {
                max-width: 240px;
              }
            }
          }
        }

        &--two {
          width: 100%;
          padding: 50px 0 0 0;
          @media ${screen.small} {
            width: 50%;
            padding: 0 0 0 50px;
          }
          @media ${screen.small} {
            padding: 0;
            width: auto;
          }
        }

        &--three {
          width: 50%;
          margin: 50px 0 0 0;
          @media ${screen.xsmall} {
            margin: 70px 0 0 0;
            width: 50%;
          }
          @media ${screen.small} {
            margin: 0;
            width: auto;
          }
        }

        &--four {
          margin: 50px 0 0 0;
          width: 50%;
          @media ${screen.xsmall} {
            margin: 70px 0 0 0;
            padding: 0 0 0 50px;
            width: 50%;
          }
          @media ${screen.small} {
            margin: 0;
            padding: 0;
            width: auto;
          }

          .link-partner {
            max-width: 98px;
            width: 100%;
            display: block;

            svg {
              width: 100%;
              display: block;
            }

            &--salesforce {
              min-width: 60px;
              max-width: 60px;
              margin: 0 0 15px 10px;
              @media ${screen.xsmall} {
                min-width: 87px;
                max-width: 87px;
                margin: 0 0 20px 10px;
              }
              @media ${screen.small} {
                margin: 0 0 25px 15px;
              }
              @media ${screen.large} {
                min-width: 100px;
                max-width: 100px;
              }
            }

            &--tableau {
              max-width: 112px;
              margin: 0 0 0 auto;
              @media ${screen.xsmall} {
                min-width: 135px;
                max-width: 135px;
              }
              @media ${screen.xsmall} {
                margin: 0 0 0 auto;
              }
              @media ${screen.large} {
                min-width: 154px;
                max-width: 154px;
              }
            }
          }

          .inner-row {
            display: flex;
            justify-content: flex-end;
          }
        }
      }
    }
  }

  .bot {
    background: ${mainWhite};
    padding: 11px 0;

    .flex {
      @media ${screen.xsmall} {
        display: flex;
        justify-content: space-between;
      }

      p {
        color: ${mainBlack};
        font-size: 0.7rem;
        line-height: 2;
        @media ${screen.xsmall} {
          font-size: 0.85rem;
          line-height: 1.4;
        }

        a {
          color: ${mainBlack};
          text-decoration: underline;
          @media ${screen.withCursor} {
            &:hover {
              color: ${mainBlue};
            }
          }
        }
      }
    }
  }
`;

const Footer = () => {
  const currentYear = new Date().getFullYear();

  const data = useStaticQuery(dataQuery);

  const {
    contact_email,
    contact_phone,
    contact_address,
    contact_address_url,
    social_platform_list,
  } = data.main.data;

  const triggerGA = {
    phone: () => ga("send", "event", "phone-number", "click"),
    email: () => ga("send", "event", "email-address", "click"),
  };

  return (
    <Wrapper>
      <div className="top">
        <div className="inner-wrapper flex">
          <div
            className="col col--one"
            data-sal="slide-up"
            data-sal-duration="500"
          >
            <Link className="logo" to="/">
              <Logo />
            </Link>
            <p className="heading">Subscribe to our newsletter</p>
            <form
              className="js-cm-form form"
              action="https://www.createsend.com/t/subscribeerror?description="
              method="post"
              data-id="191722FC90141D02184CB1B62AB3DC26C9AD7006A0D6DFFE669911502A329899FD33D7B6D318CDABC66EEA5A0743B166B7A0925C5AF982195EAD0B42219E084E"
            >
              <p>
                <input
                  type="email"
                  aria-label="Email"
                  name="cm-jlsutd-jlsutd"
                  placeholder="Your email"
                  className="js-cm-email-input qa-input-email"
                  required
                />
                <button>
                  <FaTelegramPlane />
                  <span>Subscribe</span>
                </button>
              </p>
            </form>

            <div className="social">
              <p className="heading">Follow us</p>
              <ul className="social-list">
                {social_platform_list.map((item, i) => (
                  <li key={item.platform}>
                    <a
                      href={item.url}
                      rel="noopener"
                      aria-label="Visit social platform"
                      target="_blank"
                    >
                      {item.platform}
                    </a>

                    {social_platform_list.length - 1 !== i && <span>|</span>}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div
            className="col col--two"
            data-sal="slide-up"
            data-sal-duration="500"
          >
            <h6 className="heading">Get in Touch</h6>
            <p>
              <a href={`tel:${contact_phone}`} onClick={triggerGA.phone}>
                {contact_phone}
              </a>
            </p>
            <p>
              <Obfuscate email={contact_email} onClick={triggerGA.email} />
            </p>
            <p>
              <a
                className="address"
                href={contact_address_url}
                rel="noopener"
                aria-label="Visit map address"
                target="_blank"
              >
                {contact_address}
              </a>
            </p>
          </div>

          <div
            className="col col--three"
            data-sal="slide-up"
            data-sal-duration="500"
          >
            <h6 className="heading">Explore</h6>
            <p>
              <Link to="/">Home</Link>
            </p>
            <p>
              <Link to="/services/">Services</Link>
            </p>
            <p>
              <Link to="/about/">About us</Link>
            </p>
            <p>
              <Link to="/industry/">Industry</Link>
            </p>
            <p>
              <Link to="/tableau/">ClearR + Tableau</Link>
            </p>
            <p>
              <Link to="/salesforce/">ClearR + Salesforce</Link>
            </p>
          </div>

          <div
            className="col col--four"
            data-sal="slide-up"
            data-sal-duration="500"
          >
            <div className="inner-row">
              <a
                className="link-partner link-partner--salesforce"
                href="https://www.salesforce.com/"
                aria-label="Visit Salesforce"
                rel="noopener"
                target="_blank"
              >
                <SalesforceBrand1 />
              </a>
              <a
                className="link-partner link-partner--salesforce"
                href="https://www.salesforce.com/"
                aria-label="Visit Salesforce"
                rel="noopener"
                target="_blank"
              >
                <SalesforceBrand2 />
              </a>
            </div>

            <a
              className="link-partner link-partner--tableau"
              href="https://www.tableau.com/"
              aria-label="Visit Tableau"
              rel="noopener"
              target="_blank"
            >
              <TableauBrand />
            </a>
          </div>
        </div>
      </div>

      <div className="bot">
        <div className="inner-wrapper flex">
          <p>
            &copy; ClearR {currentYear}. ALL RIGHTS RESERVED.{" "}
            <Link to="/privacy-policy/">PRIVACY POLICY</Link>
          </p>
          <p>
            Designed and built by{" "}
            <a
              href="https://www.hellohandsome.com.au/"
              rel="noopener"
              aria-label="Visit Handsome Creative"
              target="_blank"
            >
              Handsome Creative.
            </a>
          </p>
        </div>
      </div>
    </Wrapper>
  );
};

export default Footer;

const dataQuery = graphql`
  {
    main: prismicGeneralDetails {
      data {
        contact_email
        contact_phone
        contact_address
        contact_address_url
        social_platform_list {
          platform
          url
        }
      }
    }
  }
`;
