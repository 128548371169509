import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ScrollLocky } from "react-scroll-locky";
import { graphql, useStaticQuery } from "gatsby";
import { mainWhite, transHover, mainPurple, screen } from "../variables";
import { GatsbyImage } from "gatsby-plugin-image";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { motion, AnimatePresence } from "framer-motion";

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const item = {
  hidden: { opacity: 0, x: -15 },
  show: { opacity: 1, x: 0 },
};

const Wrapper = styled(motion.div)`
  background: rgba(46, 41, 78, 0.98);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;

  .container {
    background: ${mainWhite};
    border-radius: 16px;
    max-width: 850px;
    width: calc(100% - 50px);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 25px 25px 30px 25px;

    @media ${screen.xsmall} {
      display: flex;
      padding: 60px;
      border-radius: 20px;
    }

    @media ${screen.small} {
      padding: 80px;
      border-radius: 25px;
    }

    @media ${screen.medium} {
      padding: 60px;
      border-radius: 30px;
    }

    .col {
      &--left {
        position: relative;
        width: 55%;
        pointer-events: none;
        @media ${screen.xsmall} {
          width: 48%;
          padding: 0 40px 0 0;
        }
        @media ${screen.small} {
          padding: 0 50px 0 0;
        }
        @media ${screen.large} {
          padding: 0 55px 0 0;
        }
      }

      &--right {
        @media ${screen.xsmall} {
          width: 52%;
        }
        .description {
          margin: 12px 0 0 0;
          max-width: 430px;
          @media ${screen.xsmall} {
            margin: 0;
          }

          p {
            font-size: 0.95rem;
            margin: 0 0 19px 0;
            @media ${screen.xsmall} {
              font-size: 1.05rem;
            }
            @media ${screen.small} {
              font-size: 1.1rem;
            }
            @media ${screen.medium} {
              font-size: 1.15rem;
            }

            &:last-child {
              margin: 0;
            }
          }

          ul,
          ol {
            padding: 0 0 0 50px;
            li {
              font-size: 1.25rem;
              margin: 0 0 21px 0;
              line-height: 1.4;
            }
          }

          ul {
            li {
              list-style-type: disc;
            }
          }

          ol {
            li {
              list-style-type: decimal;
            }
          }
        }
      }
    }

    .close-btn {
      font-size: 1.96rem;
      position: absolute;
      right: 17px;
      top: 14px;
      cursor: pointer;
      transition: ${transHover};
      @media ${screen.xsmall} {
        font-size: 2.16rem;
        right: 25px;
        top: 25px;
      }
      @media ${screen.withCursor} {
        &:hover {
          color: ${mainPurple};
        }
      }
    }
  }
`;

const LightboxRebrand = () => {
  const data = useStaticQuery(dataQuery);
  const [isLightboxRebrand, setIsLightboxRebrand] = useState(false);
  const isActive = isLightboxRebrand;

  const { featured_image, description, is_enabled, time_delay } =
    data.main.data;

  useEffect(() => {
    const userClick = (e) => {
      if (e.target.id === "rebrand-lightbox") closeLightbox();
    };

    window.addEventListener("click", userClick);

    setTimeout(() => {
      if (window.sessionStorage.getItem("visioneer360rebrand")) {
        setIsLightboxRebrand(false);
      } else {
        setIsLightboxRebrand(true);
      }
    }, time_delay * 1000);

    return () => {
      window.removeEventListener("click", userClick);
    };
  }, []);

  const closeLightbox = () => {
    window.sessionStorage.setItem("visioneer360rebrand", true);
    setIsLightboxRebrand(false);
  };

  return (
    is_enabled && (
      <AnimatePresence>
        {isActive && (
          <Wrapper
            id="rebrand-lightbox"
            initial="hidden"
            animate={isActive ? "show" : "hidden"}
            variants={container}
            exit={{ opacity: 0 }}
          >
            <ScrollLocky isolation={false}>
              <div className="container">
                <div className="col col--left">
                  <motion.figure variants={item} className="img">
                    <GatsbyImage
                      image={featured_image.gatsbyImageData}
                      alt={featured_image.alt || "Featured"}
                    />
                  </motion.figure>
                </div>
                <motion.div variants={item} className="col col--right">
                  <div
                    className="description"
                    dangerouslySetInnerHTML={{ __html: description.html }}
                  />
                </motion.div>

                <p className="close-btn" role="button" onClick={closeLightbox}>
                  <IoIosCloseCircleOutline />
                </p>
              </div>
            </ScrollLocky>
          </Wrapper>
        )}
      </AnimatePresence>
    )
  );
};

export default LightboxRebrand;

const dataQuery = graphql`
  {
    main: prismicRebrandPopup {
      data {
        featured_image {
          alt
          gatsbyImageData(layout: FULL_WIDTH)
        }
        description {
          html
        }
        is_enabled
        time_delay
      }
    }
  }
`;
