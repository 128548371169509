import React, { useState } from "react";
import styled from "styled-components";
import {
  mainWhite,
  mainBlack,
  mainPurple,
  transHover,
  screen,
} from "../variables";
import { Link } from "gatsby";
import { AnimatePresence, motion } from "framer-motion";
import { IoIosArrowDown } from "react-icons/io";
import { ScrollLocky } from "react-scroll-locky";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.133,
    },
  },
};

const item = {
  hidden: { opacity: 0, x: -20 },
  show: { opacity: 1, x: 0 },
};

const Wrapper = styled(motion.div)`
  background: ${mainWhite};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 19;
  padding: 110px 25px 25px 25px;
  @media ${screen.xsmall} {
    padding: 160px 40px 40px 40px;
  }
  @media ${screen.small} {
    display: none;
  }

  .nav-list {
    &__each {
      margin: 0 0 30px 0;
      @media ${screen.xsmall} {
        margin: 0 0 40px 0;
      }

      a {
        font-size: 1.38rem;
        font-weight: 700;
        color: ${mainBlack};
        @media ${screen.xsmall} {
          font-size: 1.5rem;
        }
        @media ${screen.withCursor} {
          &:hover {
            color: ${mainPurple};
          }
        }
      }

      .active {
        color: ${mainPurple};
      }

      &--dropdown {
        position: relative;

        .icon-arrow {
          position: absolute;
          top: 5px;
          left: 109px;
          @media ${screen.xsmall} {
            left: 119px;
          }

          svg {
            transition: ${transHover};
          }

          &--is-open {
            svg {
              transform: rotate(-180deg) translateY(1px);
            }
          }
        }

        .sublink-list {
          padding: 20px 0 20px 20px;
          @media ${screen.xsmall} {
            padding: 20px 0 20px 40px;
          }

          &__each {
            display: block;
            white-space: pre;
            margin: 0 0 20px 0;
            @media ${screen.xsmall} {
              margin: 0 0 26px 0;
            }

            &:last-child {
              margin: 0;
            }

            a {
              font-weight: 500;
              font-size: 1.08rem;
              @media ${screen.xsmall} {
                font-size: 1.28rem;
              }
            }
          }
        }
      }
    }
  }
`;

const OverlayMenu = ({ isOverlayMenu, setIsOverlayMenu }) => {
  const [isIndustriesDropdown, setIsIndustriesDropdown] = useState(false);

  const toggleIndustriesDropdown = (e) => {
    e.preventDefault();

    setIsIndustriesDropdown(!isIndustriesDropdown);
  };

  const closeAll = () => {
    if (isIndustriesDropdown) setIsIndustriesDropdown(false);
    if (isOverlayMenu) setIsOverlayMenu(false);
  };

  return (
    <AnimatePresence>
      {isOverlayMenu && (
        <ScrollLocky enabled={isOverlayMenu} isolation={false}>
          <Wrapper
            variants={container}
            initial="hidden"
            animate={isOverlayMenu ? "show" : "hidden"}
            exit={{ opacity: 0 }}
          >
            <ul className="nav-list">
              <motion.li
                variants={item}
                onClick={closeAll}
                className="nav-list__each"
              >
                <Link activeClassName="active" to="/services/">
                  Services
                </Link>
              </motion.li>
              <motion.li
                variants={item}
                onClick={closeAll}
                className="nav-list__each"
              >
                <Link activeClassName="active" to="/about/">
                  About Us
                </Link>
              </motion.li>
              <motion.li
                variants={item}
                className="nav-list__each nav-list__each--dropdown"
              >
                <Link
                  activeClassName="active"
                  to="/industry/"
                  onClick={toggleIndustriesDropdown}
                >
                  Industries
                  <span
                    className={
                      isIndustriesDropdown
                        ? "icon-arrow icon-arrow--is-open"
                        : "icon-arrow"
                    }
                  >
                    <IoIosArrowDown />
                  </span>
                </Link>

                {isIndustriesDropdown && (
                  <div className="sublink-list">
                    <p className="sublink-list__each" onClick={closeAll}>
                      <Link to="/industry/">All</Link>
                    </p>
                    <p className="sublink-list__each" onClick={closeAll}>
                      <AnchorLink to="/industry/#financial-services">
                        Financial Services
                      </AnchorLink>
                    </p>
                    <p className="sublink-list__each" onClick={closeAll}>
                      <AnchorLink to="/industry/#government">
                        Government
                      </AnchorLink>
                    </p>
                    <p className="sublink-list__each" onClick={closeAll}>
                      <AnchorLink to="/industry/#healthcare">
                        HealthCare
                      </AnchorLink>
                    </p>
                    <p className="sublink-list__each" onClick={closeAll}>
                      <AnchorLink to="/industry/#not-for-profit">
                        Not for Profit
                      </AnchorLink>
                    </p>
                    <p className="sublink-list__each" onClick={closeAll}>
                      <AnchorLink to="/industry/#telecommunications-and-logistics">
                        Telecommunications & Logistics
                      </AnchorLink>
                    </p>
                    <p className="sublink-list__each" onClick={closeAll}>
                      <AnchorLink to="/industry/#higher-education">
                        Higher Education
                      </AnchorLink>
                    </p>
                  </div>
                )}
              </motion.li>
              <motion.li
                variants={item}
                className="nav-list__each"
                onClick={closeAll}
              >
                <Link activeClassName="active" to="/tableau/">
                  ClearR + Tableau
                </Link>
              </motion.li>
              <motion.li
                variants={item}
                className="nav-list__each"
                onClick={closeAll}
              >
                <Link activeClassName="active" to="/salesforce/">
                  ClearR + Salesforce
                </Link>
              </motion.li>
              <motion.li
                variants={item}
                className="nav-list__each"
                onClick={closeAll}
              >
                <Link activeClassName="active" to="/quickstart/">
                  Quickstart
                </Link>
              </motion.li>
            </ul>
          </Wrapper>
        </ScrollLocky>
      )}
    </AnimatePresence>
  );
};

export default OverlayMenu;
